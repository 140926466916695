import throttle from 'lodash.throttle';


function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

/**
 * @param {String} HTML representing any number of sibling elements
 * @return {NodeList}
 */
function htmlToElements(html) {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}


const playAudioIcon = htmlToElement('<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" role="img" aria-hidden="true" preserveAspectRatio="xMidYMid meet" stroke="currentColor"><g fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M15 8a5 5 0 0 1 0 8"/><path d="M17.7 5a9 9 0 0 1 0 14"/><path d="M6 15H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h2l3.5-4.5A.8.8 0 0 1 11 5v14a.8.8 0 0 1-1.5.5L6 15"/></g></svg>');
const stopAudioIcon = htmlToElement('<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" role="img" aria-hidden="true" preserveAspectRatio="xMidYMid meet" stroke="currentColor"><g fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M6 15H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h2l3.5-4.5A.8.8 0 0 1 11 5v14a.8.8 0 0 1-1.5.5L6 15"/><path d="M16 10l4 4m0-4l-4 4"/></g></svg>');
const pauseAudioIcon = htmlToElement('<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" role="img" aria-hidden="true" preserveAspectRatio="xMidYMid meet" stroke="currentColor"><g fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M6 15H4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h2l3.5-4.5A.8.8 0 0 1 11 5v14a.8.8 0 0 1-1.5.5L6 15"/><path d="M16 10l4 4m0-4l-4 4"/></g></svg>');
const reloadIcon = htmlToElement('<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" role="img" aria-hidden="true" preserveAspectRatio="xMidYMid meet" stroke="currentColor"><g fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M20 11A8.1 8.1 0 0 0 4.5 9M4 5v4h4"/><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"/></g></svg>');



export function prepareCaptchaModel(inContainer) {
    inContainer = inContainer ? inContainer : document;
    let f = 0;

    // Before Mount
    inContainer.querySelectorAll('[name="captcha"]').forEach((c) => {
        let form = c.closest('form');
        if (form && c.name) {
            if (!form.getAttribute('name')) {
                f++;
                form.setAttribute('name', 'form__'+ f);
            }

            c.setAttribute('data-model', '$models.'+ form.getAttribute('name') +'.'+ c.name);
        }
    });


    inContainer.querySelectorAll('[data-component="bbcode-editor"]').forEach((el) => {
        let form = el.closest('form');
        if (form && el.name) {
            if (!form.getAttribute('name')) {
                f++;
                form.setAttribute('name', 'form__'+ f);
            }
            el.setAttribute('data-model', '$models.'+ form.getAttribute('name') +'.'+ el.name);
        }
    });
}



export function prepareCaptcha(inContainer) {
    inContainer = inContainer ? inContainer : document;
    inContainer.querySelectorAll('[data-control="captcha"]').forEach((el) => {

        if (el.hasOwnProperty('__captcha')) return;

        el.__captcha = true;


        const domEl = el;
        const img = domEl.querySelector('img');
        const captchaInput = domEl.parentNode.querySelector('input[name=captcha]');
        const audioLink = domEl.querySelector('.captcha-audio');
        const reloadLink = domEl.querySelector('.captcha-reload');

        // Replace Icons
        const audioIcon = audioLink.querySelector('.tabler-icon');
        if (audioIcon) {
            audioIcon.replaceWith(playAudioIcon);
        }
        else {
            audioLink.append(playAudioIcon);
        }

        if (reloadLink) {
            const reloadIcons = reloadLink.querySelector('.tabler-icon');
            if (reloadIcons) {
                reloadIcons.replaceWith(reloadIcon);
            }
            else {
                reloadLink.append(reloadIcon);
            }
        }


        if (audioLink)
        {
            audioLink.addEventListener('click', (ev) => {
                ev.preventDefault();

                const el = ev.currentTarget.closest('[data-control="captcha"]');
                if (el) {
                    const mp3 = ev.currentTarget.mp3;
                    const icon = ev.currentTarget.querySelector('svg');

                    if (mp3)
                    {
                        if (mp3.paused)
                        {
                            mp3.play();
                            if (icon) icon.replaceWith(pauseAudioIcon);

                        }
                        else if (mp3.played)
                        {
                            mp3.pause();
                            if (icon) icon.replaceWith(playAudioIcon);
                        }
                    }
                    else
                    {
                        if (icon) icon.replaceWith(pauseAudioIcon);

                        ev.currentTarget.mp3 = new Audio(domEl.dataset.audiourl);
                        ev.currentTarget.mp3.addEventListener('ended',  () =>
                        {
                            if (icon) icon.replaceWith(playAudioIcon);
                        });

                        ev.currentTarget.mp3.play();
                    }
                }
            });
        }


        if (reloadLink)
        {
            reloadLink.addEventListener('click', (ev) => {
                ev.preventDefault();

                if (audioLink)
                {

                    const mp3 = audioLink.mp3;
                    const icon = audioLink.querySelector('svg');
                    if (icon) icon.replaceWith(playAudioIcon);

                    if (mp3 && mp3.played)
                    {
                        mp3.pause();
                        audioLink.mp3 = null;
                    }
                }

                img.setAttribute('src', domEl.dataset.reloadurl + '?_t=' + (new Date()).getTime());
                // clear text value
                if (captchaInput) {
                    captchaInput.value = null;
                    captchaInput.setAttribute('value', '')
                    captchaInput.dispatchEvent(new Event('change'))
                }
            });
        }


    });
}




function isScrolledIntoView(el, space = 0) {
    let rect = el.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    return (elemTop + space >= 0) && (elemBottom - space <= window.innerHeight);
}

export function preparePageDefaults() {

    let logo = document.querySelector('.logo-wrapper .logo svg')
    if (logo) {
        logo.classList.add('active')
    }

    let svgLines = document.querySelectorAll('.svg-line')
    let switchsplatter = document.querySelector('.switch-splatter svg')

    window.addEventListener('scroll', throttle(() => {

        if (switchsplatter) {
            if (isScrolledIntoView(switchsplatter)) {
                switchsplatter.classList.add('active')
            }
            else {
                switchsplatter.classList.remove('active')
            }
        }

        svgLines.forEach((s) => {
            let v = isScrolledIntoView(s, 300);
            if (v) {
                s.classList.add('active');
            }
            else {
                s.classList.remove('active');
            }
        })

    }, 99) )


    prepareForms();
    prepareFormErrors();


    // Frontpage
    document.querySelectorAll('.tool-selectors button').forEach((btn, i) => {
        if (i === 0) btn.classList.add('btn-writer')
        if (i === 1) btn.classList.add('btn-developers')
        btn.addEventListener('click', (evt) => {
            evt.preventDefault();
            if (evt.target.classList.contains('btn-writer')) {
                document.querySelectorAll('.writer .svg-line').forEach((l) => {
                    l.classList.remove('active')
                })
            }
            else if (evt.target.classList.contains('btn-developers')) {
                document.querySelectorAll('.developers .svg-line').forEach((l) => {
                    l.classList.remove('active')
                })
            }
        })
    });

    const isDocuPage = document.body.classList.contains('docu-page')


    // -------- Bookmarks
    document.querySelectorAll('a[href^="#"]').forEach((el) =>
    {
        if (!el.dataset.hasOwnProperty('toggle') && !el.closest('nav'))
        {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();

                let s, href = e.target.href;
                href = href.replace(/.*\#([a-z0-9_\-]+)/ig, '#$1');

                if (href && (s = document.querySelector(href)) && s) {
                    window.scrollTo({
                        top: s.offsetTop,
                        left: 0,
                        behavior: "smooth",
                    });

                    // if (isDocuPage) {
                    //     window.scrollTo({
                    //         top: s.offsetTop,
                    //         left: 0,
                    //         behavior: "smooth",
                    //     });
                    // }
                    // else {
                    //     document.querySelector(href).scrollIntoView();
                    // }
                }
            });
        }
    });






    prepareScrollTopButton();
    prepareExternalLinks();

    prepareDocuPage();

}



function prepareDocuPage() {

    if (document.body.classList.contains('docu-page')) {
        const docuContent = document.querySelector('.docu-content');

        const config = {
            lang: 'plain',
            linenumbers: true,
            theme: 'dracula'
        };

        if (docuContent) {
            docuContent.querySelectorAll('code[class*=language-]').forEach((ele) => {

                let pre = ele.closest('pre');
                if (config.linenumbers) {

                    if (pre && !pre.classList.contains('language-treeview') ) {
                        pre.setAttribute('data-pre', '');
                        pre.setAttribute('data-skip', '');

                        if (!pre.classList.contains('line-numbers')) {
                            pre.classList.add('line-numbers');
                        }
                    }
                }

                if ( ele.classList.contains('language-treeview') && pre ) {
                    pre.classList.remove('line-numbers');

                    Prism.language = 'treeview';
                }

                Prism.highlightElement(ele);
            });
        }

    }

}



export function prepareForms() {
    const main = document.querySelector('main');
    if (main) {
        main.querySelectorAll('input,select,textarea').forEach((el) => {

            let formField = el.closest('.form-field');
            if (formField) {
                let formFieldLabel = formField.querySelector('.field-label');
                if (formFieldLabel) {



                    if (el.getAttribute('placeholder'))
                    {
                        let inLabel = formFieldLabel.querySelector('label');
                        if (inLabel) {
                            inLabel.innerHTML = el.getAttribute('placeholder');
                        }
                        else {
                            formFieldLabel.innerHTML = el.getAttribute('placeholder');
                        }

                        el.removeAttribute('placeholder')
                    }

                    // Add "has-value" class, if field is not empty
                    if (el.value) {
                        formField.classList.add('has-value');
                    }




                    if (el.getAttribute('type') !== 'number') {

                        el.addEventListener('change', (e) => {
                            // e.preventDefault();
                            if (e.target.value) {
                                formField.classList.add('has-value');
                            }
                            else {
                                formField.classList.remove('has-value');
                            }
                        });
                    }
                    else {
                        // Add change event on number text field

                        el.addEventListener('change', (evt) => {
                            let val = evt.target.value;
                            let min = evt.target.getAttribute('min');
                            let max = evt.target.getAttribute('max');

                            if (max !== undefined && val >= parseInt(max))  {
                                evt.target.value = parseInt(max);
                            }

                            if (min !== undefined && parseInt(min) >= val)  {
                                evt.target.value = parseInt(min);
                            }

                            if (evt.target.value) {
                                formField.classList.add('has-value');
                            }
                            else {
                                formField.classList.remove('has-value');
                            }
                        })
                    }




                    el.addEventListener('focus', (e) => {
                        // e.preventDefault();
                        formField.classList.add('is-focused');
                    });

                    el.addEventListener('blur', (e) => {
                        // e.preventDefault();
                        formField.classList.remove('is-focused');
                    });
                }
                else {
                    if (el.getAttribute('type') === 'number') {
                        bindChangeEventOnNumberField(el);
                    }
                }
            }
            else {
                if (el.getAttribute('type') === 'number') {
                    bindChangeEventOnNumberField(el);
                }
            }
        });
    }
}



function bindChangeEventOnNumberField(el) {
    el.addEventListener('change', (evt) => {
        let val = evt.target.value;
        let min = evt.target.getAttribute('min');
        let max = evt.target.getAttribute('max');

        if (max !== undefined && val >= parseInt(max))  {
            evt.target.value = parseInt(max);
        }

        if (min !== undefined && parseInt(min) >= val)  {
            evt.target.value = parseInt(min);
        }
    })
}



export function prepareScrollTopButton() {
    // Bind Scrolltop
    let scrollTop = document.querySelector('#scroll-top');
    if (scrollTop) {

        let bodyScope = null;
        if (document.body && document.body.hasOwnProperty('__lv_scope')) {
            bodyScope = document.body.__lv_scope
        }


        let header = document.querySelector('.app > nav');

        if (!header) {

        }
        else {

            if (header && window.scrollY > header.offsetHeight) {
                document.body.classList.add('display-scroll-top');


                if (bodyScope) {
                    if (bodyScope.hasOwnProperty('sticky_header_slide_down') && bodyScope.sticky_header_slide_down) {
                        if (window.scrollY > header.offsetHeight) {
                            document.body.classList.add('sticky-header-slide');
                        } else {
                            document.body.classList.remove('sticky-header-slide');
                        }
                    }

                    if (bodyScope.hasOwnProperty('sticky_header') && bodyScope.sticky_header) {
                        if (window.scrollY > header.offsetHeight) {
                            document.body.classList.add('sticky-header');
                        } else {
                            document.body.classList.remove('sticky-header');
                        }
                    }
                }
            }
        }



        document.addEventListener('scroll', (e) => {
            e.preventDefault();

            if (header) {
                if (window.scrollY > header.offsetHeight) {
                    document.body.classList.add('display-scroll-top');
                } else {
                    document.body.classList.remove('display-scroll-top');
                }

                if (bodyScope) {
                    if (bodyScope.hasOwnProperty('sticky_header_slide_down') && bodyScope.sticky_header_slide_down) {
                        if (window.scrollY > header.offsetHeight) {
                            document.body.classList.add('sticky-header-slide');
                        } else {
                            document.body.classList.remove('sticky-header-slide');
                        }
                    }

                    if (bodyScope.hasOwnProperty('sticky_header') && bodyScope.sticky_header) {
                        if (window.scrollY > header.offsetHeight) {
                            document.body.classList.add('sticky-header');
                        } else {
                            document.body.classList.remove('sticky-header');
                        }
                    }
                }
            }
            else {

                let minHeight = scrollTop.offsetHeight;

                if (window.scrollY > minHeight) {
                    document.body.classList.add('display-scroll-top');
                } else {
                    document.body.classList.remove('display-scroll-top');
                }

            }
        });

        scrollTop.addEventListener('click', (event) => {
            event.preventDefault();
            document.body.scrollIntoView({block: "start", behavior: "smooth"});
        });
    }
}


export function prepareExternalLinks() {
    let base = document.querySelector('base');
    const baseUrl = base && base.href ? base.href : '';
    const hostRegex = new RegExp('^(https?://'+ location.hostname +'|:?//'+ location.hostname +').*', 'ig');
    document.querySelectorAll('main a').forEach((el) => {
        if (el.href && !el.href.match(/^(javascript:|mailto:|\#)/g) && !el.href.match(/#$/g))
        {
            if ( !el.href.match(hostRegex) ) {
                el.classList.add('link-external');
            }
        }
    });
}




export function prepareFormErrors() {
    document.querySelectorAll('li[data-field-error]').forEach((el) => {

        let fel = document.querySelector('[name="'+ el.dataset.fieldError +'"]');
        if (fel.parentNode.classList.contains('hp')) {
            el.remove();
        }
        else {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                if (e.currentTarget.dataset.fieldError && document.querySelector('[name="'+ e.currentTarget.dataset.fieldError +'"]')) {

                    let se = document.querySelector('[name="'+ e.currentTarget.dataset.fieldError +'"]');
                    if (se) {
                        let bound;
                        if (se.closest('.form-field')) {
                            bound = se.closest('.form-field');
                        }
                        else bound = se.parentNode;
                        const yOffset = -70;
                        const y = bound.getBoundingClientRect().top + window.scrollY + yOffset;

                        // Scroll to form field
                        window.scrollTo({
                            top: y,
                            behavior: "smooth", block: "start", inline: "nearest"
                        });

                        // Then make focus
                        se.focus()
                    }
                }
            });
        }
    });
}


export function prepareBlog() {
    let blogpage = document.querySelector('body.blog-page');
    if (blogpage) {
        let singlePost = blogpage.querySelector('.single-post-area .single-post');
        if (singlePost) {

            // let baseUrl = document.
            const regex = new RegExp(window.location.host, 'g')
            singlePost.querySelectorAll('a').forEach((link) => {
                if (link.href) {
                    if (!regex.test(link.href)) {
                        link.setAttribute('target', '_blank')
                        link.classList.add('link-external');
                    }
                }
            });

            singlePost.querySelectorAll('img').forEach((img) => {
                if (img.parentNode.tagName === 'P') {
                    let w = 0, h = 0;
                    const imgTest = new Image();
                    imgTest.onload = function() {
                        w = this.width;
                        h = this.height;
                        if (w >= 400) {
                            let otherElements = img.parentNode.querySelectorAll('*:not(img)');
                            if (!otherElements.length) {
                                let figure = document.createElement('figure');
                                img.after(figure);
                                figure.appendChild(img);

                                if (img.getAttribute('title')) {
                                    let caption = document.createElement('figcaption');
                                    caption.innerHTML = '<p>'+ img.getAttribute('title') +'</p>';
                                    figure.appendChild(caption);
                                }
                                else{
                                    figure.classList.add('without-caption');
                                }

                            }
                        }
                    };
                    imgTest.src = img.src;
                }



            });
        }
    }
}

export function prepareNoneHljsComponents() {
    // let elements = document.querySelectorAll('code');
    // if (elements.length) {
    //     elements.forEach((el) => {
    //         let cls = el.getAttribute('class');
    //         if (cls && cls.match(/language\-/g) && !el.hasAttribute('HighlightJs')) {
    //             Prism.highlightElement(el, true);
    //         }
    //     });
    // }
}
