import {isObject} from "../framework/utils";
import {insertAfter} from "../framework/DomHelpers";

export const addFormError = (afterElement: any, message: string) => {

	if (typeof afterElement === "string") {
		afterElement = document.querySelector(afterElement);
	}

	if (afterElement.type === 'file') {
		afterElement = afterElement.parentNode;
	}


    if (afterElement.parentNode.querySelector('.error')) {
        afterElement.parentNode.querySelector('.error').remove()
    }

	let warning = document.createElement('div');
	warning.classList.add('error');
	warning.innerHTML = message;

	insertAfter(warning, afterElement);
}

export const hasAjaxNotification = (response: Object) => {
	// @ts-ignore
	if (response.hasOwnProperty('success')) {
		// @ts-ignore
		if (response.hasOwnProperty('notification') && typeof response.notification === "object") {
			return true;
		}

		if (response.hasOwnProperty('message')) {
			return true;
		}

		if (response.hasOwnProperty('errors')) {
			return true;
		}
	}

	return false;
}

export const ajaxNotification = (response: Object, callback?: Function) => {

	if (response.hasOwnProperty('success')) {
// @ts-ignore
        if (!response.success && response.hasOwnProperty('errors')) {// @ts-ignore
            if (isObject(response.errors)) {// @ts-ignore
                let allErrors = [];// @ts-ignore

                // @ts-ignore
                Object.keys(response.errors).forEach((fieldName) => {// @ts-ignore
                    let field = document.querySelector('[name='+ fieldName +']');
                    if (field) {
                        // @ts-ignore
                        addFormError(field, response.errors[fieldName][0]);
                        // @ts-ignore
                        allErrors.push(response.errors[fieldName][0]);
                    }
                });
// @ts-ignore
                if (allErrors.length) {


                    let c;
                    if ((c = document.querySelector('.notify-container'))) {
                        c.remove();
                    }


                    let notify = document.createElement('div');
                    notify.classList.add('notify-container')
                    notify.classList.add('type-error')
                    // @ts-ignore
                    notify.innerHTML = '<div>'+ allErrors.join('<br>') +'</div>'
                    document.body.appendChild(notify);

                    setTimeout(() => {
                        notify.classList.add('show')
                        // @ts-ignore
                        let t = setTimeout(() => {
                            notify.classList.remove('show');
                            setTimeout(() => {
                                notify.remove();
                            }, 310);
                            // @ts-ignore
                        }, response.delay ?? 3000);

                        notify.addEventListener('click', (e) => {
                            if (t) clearTimeout(t);
                            notify.classList.remove('show');
                            setTimeout(() => {
                                notify.remove();
                            }, 310);
                        })
                    }, 1);


                    return;
                }
            }
        }

		// @ts-ignore
		if (response.hasOwnProperty('notification') && typeof response.notification === "object") {

			let c;
			if ((c = document.querySelector('.notify-container'))) {
				c.remove();
			}

			// @ts-ignore
			let type = response.notification.type;
			if (type === 'danger') type = 'error';

			let notify = document.createElement('div');
			notify.classList.add('notify-container')
			notify.classList.add('type-'+ type)
			// @ts-ignore
			notify.innerHTML = '<div>'+ response.notification.msg +'</div>'
			document.body.appendChild(notify);

			setTimeout(() => {
				notify.classList.add('show')
// @ts-ignore
				let t = setTimeout(() => {
					notify.classList.remove('show');
					setTimeout(() => {
						notify.remove();
					}, 310);
					// @ts-ignore
				}, response.notification.delay);

				notify.addEventListener('click', (e) => {
					if (t) clearTimeout(t);
					notify.classList.remove('show');
					setTimeout(() => {
						notify.remove();
					}, 310);
				})
			}, 50);

			return;
		}


	}

	if (response.hasOwnProperty('message')) {
		if (!document.querySelector('.notify-container')) {


			let c;
			if ((c = document.querySelector('.notify-container'))) {
				c.remove();
			}


			let notify = document.createElement('div');
			notify.classList.add('notify-container')
			notify.classList.add('type-error')
			// @ts-ignore
			notify.innerHTML = '<div>'+ response.message +'</div>'
			document.body.appendChild(notify);

			setTimeout(() => {
				notify.classList.add('show')

				let t = setTimeout(() => {
					notify.classList.remove('show');
					setTimeout(() => {
						notify.remove();
					}, 310);
				}, 3000);

				notify.addEventListener('click', (e) => {
					if (t) clearTimeout(t);
					notify.classList.remove('show');
					setTimeout(() => {
						notify.remove();
					}, 310);
				})
			}, 50);
		}
	}

	if (response.hasOwnProperty('errors')) {
		// @ts-ignore
		if (isObject(response.errors)) {
// @ts-ignore
			let allErrors = [];

			// @ts-ignore
			Object.keys(response.errors).forEach((fieldName) => {
				let field = document.querySelector('[name='+ fieldName +']');
				if (field) {
					// @ts-ignore
					addFormError(field, response.errors[fieldName][0]);
					// @ts-ignore
					allErrors.push(response.errors[fieldName][0]);
				}
			});
// @ts-ignore
			if (allErrors.length) {


				let c;
				if ((c = document.querySelector('.notify-container'))) {
					c.remove();
				}


				let notify = document.createElement('div');
				notify.classList.add('notify-container')
				notify.classList.add('type-error')
				// @ts-ignore
				notify.innerHTML = '<div>'+ allErrors.join('<br>') +'</div>'
				document.body.appendChild(notify);

				setTimeout(() => {
					notify.classList.add('show')
					// @ts-ignore
					let t = setTimeout(() => {
						notify.classList.remove('show');
						setTimeout(() => {
							notify.remove();
						}, 310);
						// @ts-ignore
					}, response.delay ?? 3000);

					notify.addEventListener('click', (e) => {
						if (t) clearTimeout(t);
						notify.classList.remove('show');
						setTimeout(() => {
							notify.remove();
						}, 310);
					})
				}, 1);
			}
		}
	}
}
