
import {nextTick} from "@/framework/scheduler";
import {getNextNode, insertAfter} from "@/framework/DomHelpers";

import {ajaxNotification, hasAjaxNotification} from "@/utils/ajax";
import {convertJsonToFormData} from "@/framework/utils";
import {effect} from "@/framework/directives/effect";

import {makeModal} from "@/framework/components/modal";


// import {svgIcon} from "@/framework/svg-icons";
import {refreshIcon} from "@/icons";
import {createScopedContext} from "@/framework/context";

const bindForumPosts = (el, ctx, options) => {
	if (el && !el.mounted) {

		el.mounted = true;
		const editBtn = el.querySelector('.edit-btn');
		const replyBtn = el.querySelector('.reply-btn');
		const oldData = options.hasOwnProperty('oldReplyData') ? options.oldReplyData : {};

		let loadOldReplayForm = false;
		if (options.hasOwnProperty('oldReplyData') && typeof options.oldReplyData === "object" ) {
			loadOldReplayForm = true;
		}

		if (editBtn)
		{
			// editBtn.addEventListener('click', (e) => {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// });
		}

		if (replyBtn && options.hasOwnProperty('inlineReplyUrl') && options.inlineReplyUrl ) {
			let replayContainer = getNextNode(el);
			let replayFormContainer;


			if (!replayContainer || (replayContainer && (replayContainer.nodeType === 3 || (replayContainer.classList && !replayContainer.classList.contains('replay-container')) ) ) )
            {
				replayContainer = document.createElement('div');
				replayContainer.classList.add('replay-container');

				let div = document.createElement('div');
				div.innerHtml = '<button type="button" class="btn btn-default cancel-reply">Abbrechen</button>';
				replayContainer.appendChild(div);


				replayFormContainer = document.createElement('div');
				replayFormContainer.classList.add('replay-form');
				replayContainer.appendChild(replayFormContainer);

				insertAfter(replayContainer, el);
			}
			else {
				replayFormContainer = replayContainer.querySelector('.replay-form');
			}

			let cancel = replayContainer.querySelector('.cancel-reply');
			if (!cancel) {
				replayContainer.querySelector('div:first-of-type').innerHTML = '<button type="button" class="btn btn-default cancel-reply">Abbrechen</button>';
				cancel = replayContainer.querySelector('.cancel-reply');
			}


			if (cancel) {
				cancel.addEventListener('click', (e) => {
					e.preventDefault();
					e.stopPropagation();
					replayFormContainer.innerHTML = '';
					replayContainer.classList.remove('open');
				});
			}


			replyBtn.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				// Close all other opened Forms
				el.parentNode.querySelectorAll('.replay-container.open').forEach((container) => {
					container.classList.remove('open');
					let replayCon = container.querySelector('.replay-form');
					if (replayCon) {
						replayCon.innerHTML = '';
					}
				});


				axios.get(options.inlineReplyUrl)
					.then((r) => {
					if (r.data.success) {
						replayFormContainer.innerHTML = r.data.form;
						ctx.scope.walk(replayFormContainer, ctx);

						if (loadOldReplayForm) {
							let input;
							if (oldData.hasOwnProperty('created_by_name')) {
								if ((input = replayContainer.querySelector('[name=created_by_name]'))) {
									input.value = oldData.created_by_name;
								}
							}

							if (oldData.hasOwnProperty('created_by_email')) {
								if ((input = replayContainer.querySelector('[name=created_by_email]'))) {
									input.value = oldData.created_by_email;
								}
							}

							if (oldData.hasOwnProperty('title')) {
								if ((input = replayContainer.querySelector('[name=title]'))) {
									input.value = oldData.title;
								}
							}

							if (oldData.hasOwnProperty('content')) {
								if ((input = replayContainer.querySelector('[name=content]'))) {
									input.value = oldData.content;
								}
							}
						}

						replayContainer.classList.add('open');
						replayContainer.scrollIntoView({ behavior: 'smooth', block: 'start'});

					}
					else {
						ajaxNotification(r.data);
					}
				});

				console.log('replyBtn');
			});


			if (loadOldReplayForm) {
				const replyEvent = new Event('click');
				replyBtn.dispatchEvent(replyEvent);
			}
		}
	}
};

const forumInlineReply = (el, ctx, exp, get, options) => {
	ctx.scope.walk(el, ctx);

	let oldInnerHtml = null;

	const SubmitHandler = (evt, _btn, form, formName) => {
		if (!formName || form.submitting) {
			return;
		}

		oldInnerHtml = oldInnerHtml ? oldInnerHtml : _btn.innerHTML;
		_btn.classList.add('btn-loading');
		_btn.innerHTML = 'Saving ...';
		form.submitting = true;

		// Remove errors
		app.removeFormErrors(el);

		nextTick(() => {
			const model = ctx.scope.$models.hasOwnProperty(form.name) ? ctx.scope.$models[form.name] : {};
			const formData = convertJsonToFormData(model);

			// Send via ajax
			axios.post(form.action, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then((r) => {

				app.ajaxNotification(r.data);
				_btn.classList.remove('btn-loading');
				_btn.innerHTML = oldInnerHtml;

				if (r.data.success) {
					if (app.hasAjaxNotification(r.data)) {
						setTimeout(() => {
							form.submitting = false;
							window.location.reload();
						}, 1000);
					}
					else {
						form.submitting = false;
						window.location.reload();
					}
				}
				else {
					form.submitting = false;
				}
			}).catch(err => {
				_btn.classList.remove('btn-loading');
				_btn.innerHTML = oldInnerHtml;
				form.submitting = false;
				console.log(err);
			});
		});


	};


	const submitForm = el.tagName === 'FORM' ? el : el.querySelector('form');
	const btn = submitForm.querySelector('[type=submit]');
	if (submitForm) {

		submitForm.addEventListener('submit', (e) => {
			e.preventDefault();
			return false;
		});

		submitForm.addEventListener('reset', (e) => {
			e.preventDefault();
			nextTick(() => {
				submitForm.reset();
			});
		});

		if (btn) {

			if (!btn.querySelector('span')) {


				const i = refreshIcon; // svgIcon('refresh');

				btn.innerHTML = '<span class="btn-loader">'+ i +'</span>'+ btn.innerHTML;
			}


			btn.addEventListener('click', (e) => {
				e.preventDefault();
				SubmitHandler(e, btn, submitForm, submitForm.name);
			});
		}
	}



};



const componentForumPost = (el, ctx, exp, get, options) => {
	bindForumPosts(el, ctx, options);
};




function bindForumMod(el, ctx, exp, get, options) {
    if (el && !el.mounted) {
        // ctx.scope.walk(el, ctx);
        el.mounted = true;


        let form = el.tagName === 'FORM' ? el : el.querySelector('form');


        const deletedConfirmModal = document.querySelector('#delete-threads');
        const moveModal = document.querySelector('#move-threads');



        const modActionBtn = el.querySelector('.mod-action-btn');
        const actionSelect = el.querySelector('.mod-action-selector');

        const checkboxAll = el.querySelector('input[name="all_threads"]');
        const checkboxes = el.querySelectorAll('input[name="threads[]"]');


        if (!form || !modActionBtn || !actionSelect || !checkboxes.length) {
            console.warn('Invalid mod-action elements')
            return;
        }

        console.log(ctx.scope)


        const setModel = (propname, value) => {
            if (ctx.scope.$models.hasOwnProperty(form.name)) {
                if (ctx.scope.$models[form.name].hasOwnProperty(propname)) {
                    ctx.scope.$models[form.name][propname] = value;
                }
            }
        };


        checkboxAll.addEventListener('change', (e) => {
            if (e.target.checked) {
                let ids = [];
                checkboxes.forEach((r) => {
                    if (r.value) {
                        ids.push(r.value);
                    }
                })
                setModel('threads', ids);
                checkboxAll.classList.remove('not-all');
            }
            else {
                setModel('threads', []);
                checkboxAll.classList.remove('not-all');
            }
        });



        let canSubmit = false;
        const deleteCtx = createScopedContext(ctx, {
            $root: deletedConfirmModal
        });

        const moveCtx = createScopedContext(ctx, {
            $root: moveModal,
        });

        ctx.effect(() => {

            if (ctx.scope.$models[form.name] && ctx.scope.$models[form.name].hasOwnProperty('threads')) {
                canSubmit = ctx.scope.$models[form.name].threads.length > 0 && ctx.scope.$models[form.name].action
            }

            if (checkboxAll.checked) {
                if (checkboxes.length > ctx.scope.$models[form.name].threads.length) {
                    checkboxAll.classList.add('not-all');
                }
                else {
                    checkboxAll.classList.remove('not-all');
                }
            }

            if (!canSubmit) {
                modActionBtn.classList.add('btn-disabled');
                modActionBtn.setAttribute('disabled', '')
            }
            else {
                modActionBtn.classList.remove('btn-disabled');
                modActionBtn.removeAttribute('disabled')
            }
        });






        modActionBtn.addEventListener('click', (e) => {
            e.preventDefault();
            if (canSubmit) {

                switch (ctx.scope.$models[form.name].action) {
                    case 'delete':
                        if (deletedConfirmModal) {
                            if (deletedConfirmModal.__modal) {
                                deletedConfirmModal.__modal.open();
                            }
                            else {

                                deletedConfirmModal.__modal = makeModal(deleteCtx, '', get, {
                                    target: '#delete-threads',
                                    submit: true,
                                    events: {
                                        onSubmit: (evt, handlerCtx) => {
                                            evt.preventDefault();
                                            setTimeout(()=> handlerCtx.scope.close(), 1000 );
                                        }
                                    }
                                });

                                deletedConfirmModal.__modal.open()
                            }
                        }
                        break;

                    case 'move':
                        if (moveModal) {
                            if (moveModal.__modal) {
                                moveModal.__modal.open();
                            }
                            else {
                                const changeMoveModalSelect = (evtn, s) => {
                                    if (evtn.target.value) {
                                        s.removeAttribute('disabled');
                                    }
                                    else  {
                                        s.setAttribute('disabled', '');
                                    }
                                }

                                moveModal.__modal = makeModal(moveCtx, '', get, {
                                    target: '#move-threads',
                                    submit: true,
                                    reset: false,
                                    draggable: true,
                                    events: {


                                        beforeSubmit: (modal, handlerCtx, postData, modalform) => {
                                            let scopedForm = handlerCtx.scope.getScopedForm();
                                            let threads = ctx.scope.$models[form.name].threads;
                                            let toForum = null;

                                            if (scopedForm) {
                                                toForum = scopedForm.$models.move_to_forum.toForum;
                                            }

                                            return Object.assign(postData, {
                                                threads: threads,
                                                toForum: toForum
                                            });
                                        },

                                        onSubmit: (evt, handlerCtx, handlerProps) => {
                                            evt.preventDefault();
                                            let scopedForm = handlerCtx.scope.getScopedForm();
                                            let threads = ctx.scope.$models[form.name].threads;
                                            let toForum = null;
                                            if (scopedForm) {
                                                toForum = scopedForm.$models.move_to_forum.toForum;
                                            }
                                            setTimeout(()=> handlerCtx.scope.close(), 1000 );
                                        },

                                        afterRender: (handlerCtx, handlerOptions, handlerProps) => {
                                            let s = handlerProps.submit;
                                            s.setAttribute('disabled', '');

                                            let scopedForm = handlerCtx.scope.getScopedForm();

                                            if (scopedForm) {
                                                moveCtx.effect( () => {
                                                    console.log(arguments)
                                                    if (scopedForm.$models.move_to_forum.toForum) {
                                                        s.removeAttribute('disabled');
                                                    } else {
                                                        s.setAttribute('disabled', '');
                                                    }
                                                }, scopedForm);
                                            }


                                        },
                                    }
                                });

                                moveModal.__modal.open()
                            }
                        }
                        break;

                    default:
                        form.submit();
                        break;
                }


            }
        })



    }
}

const componentForumMod = (el, ctx, exp, get, options) => {
    bindForumMod(el, ctx, options);
};




function bindForumPostsMod(el, ctx, exp, get, options) {
	if (el && !el.mounted) {
		// ctx.scope.walk(el, ctx);
		el.mounted = true;


		let form = el.tagName === 'FORM' ? el : el.querySelector('form');


		const deletedConfirmModal = document.querySelector('#delete-threads');
		const moveModal = document.querySelector('#move-threads');

		const modActionBtn = el.querySelector('.mod-action-btn');
		const actionSelect = el.querySelector('.mod-action-selector');

		const checkboxAll = el.querySelector('input[name="all_posts"]');
		const checkboxes = el.querySelectorAll('input[name="posts[]"]');


		if (!form || !modActionBtn || !actionSelect || !checkboxes.length) {
			console.warn('Invalid mod-action elements')
			return;
		}

		console.log(ctx.scope)


		const setModel = (propname, value) => {
			if (ctx.scope.$models.hasOwnProperty(form.name)) {
				if (ctx.scope.$models[form.name].hasOwnProperty(propname)) {
					ctx.scope.$models[form.name][propname] = value;
				}
			}
		};


		if (checkboxAll) {
			checkboxAll.addEventListener('change', (e) => {
				if (e.target.checked) {
					let ids = [];
					checkboxes.forEach((r) => {
						if (r.value) {
							ids.push(r.value);
						}
					})
					setModel('posts', ids);
					checkboxAll.classList.remove('not-all');
				}
				else {
					setModel('posts', []);
					checkboxAll.classList.remove('not-all');
				}
			});
		}




		let canSubmit = false;
		const deleteCtx = createScopedContext(ctx, {
			$root: deletedConfirmModal
		});

		const moveCtx = createScopedContext(ctx, {
			$root: moveModal,
		});

		ctx.effect(() => {

			if (ctx.scope.$models[form.name] && ctx.scope.$models[form.name].hasOwnProperty('posts')) {
				canSubmit = ctx.scope.$models[form.name].posts.length > 0 && ctx.scope.$models[form.name].action
			}

			if (checkboxAll) {
				if (checkboxAll.checked) {
					if (checkboxes.length > ctx.scope.$models[form.name].posts.length) {
						checkboxAll.classList.add('not-all');
					}
					else {
						checkboxAll.classList.remove('not-all');
					}
				}
			}


			if (!canSubmit) {
				modActionBtn.classList.add('btn-disabled');
				modActionBtn.setAttribute('disabled', '')
			}
			else {
				modActionBtn.classList.remove('btn-disabled');
				modActionBtn.removeAttribute('disabled')
			}
		});






		modActionBtn.addEventListener('click', (e) => {
			e.preventDefault();
			if (canSubmit) {

				switch (ctx.scope.$models[form.name].action) {
					case 'delete':
						if (deletedConfirmModal) {
							if (deletedConfirmModal.__modal) {
								deletedConfirmModal.__modal.open();
							}
							else {

								deletedConfirmModal.__modal = makeModal(deleteCtx, '', get, {
									target: '#delete-posts',
									submit: true,
									events: {
										onSubmit: (evt, handlerCtx) => {
											evt.preventDefault();
											setTimeout(()=> handlerCtx.scope.close(), 1000 );
										}
									}
								});

								deletedConfirmModal.__modal.open()
							}
						}
						break;

					case 'move':
						if (moveModal) {
							if (moveModal.__modal) {
								moveModal.__modal.open();
							}
							else {
								const changeMoveModalSelect = (evtn, s) => {
									if (evtn.target.value) {
										s.removeAttribute('disabled');
									}
									else  {
										s.setAttribute('disabled', '');
									}
								}

								moveModal.__modal = makeModal(moveCtx, '', get, {
									target: '#move-threads',
									submit: true,
									reset: false,
									draggable: true,
									events: {
										onSubmit: (evt, handlerCtx, handlerProps) => {
											evt.preventDefault();
											let scopedForm = handlerCtx.scope.getScopedForm();
											if (scopedForm) {
												console.log(scopedForm.$models.move_to_form.to_forum, ctx.scope.$models[form.name].threads)
											}

											setTimeout(()=> handlerCtx.scope.close(), 1000 );
										},

										afterRender: (handlerCtx, handlerOptions, handlerProps) => {
											let s = handlerProps.submit;
											s.setAttribute('disabled', '');

											let scopedForm = handlerCtx.scope.getScopedForm();

											if (scopedForm) {
												moveCtx.effect( () => {
													console.log(arguments)
													if (scopedForm.$models.move_to_form.to_forum) {
														s.removeAttribute('disabled');
													} else {
														s.setAttribute('disabled', '');
													}
												}, scopedForm);
											}


										},
									}
								});

								moveModal.__modal.open()
							}
						}
						break;


					default:

                        if (options.hasOwnProperty(ctx.scope.$models[form.name].action + 'Url')) {
                            let url = options[ctx.scope.$models[form.name].action + 'Url'];
                            if (url) {
                                form.setAttribute('action', url);
                                form.submit();
                            }
                        }
						break;
				}


			}
		})



	}
}
const componentForumPostsMod = (el, ctx, exp, get, options) => {
	bindForumPostsMod(el, ctx, exp, get, options);
};




export default {
	install(app, options) {
		app.beforeMount((app, el, ctx) => {
			const submitForm = el.tagName === 'FORM' ? el : el.querySelector('[data-component="forumInlineReply"]');
			if (submitForm && submitForm.dataset.component === 'forumInlineReply' && submitForm.name === 'inline_reply') {
				const allFields = submitForm.querySelectorAll('input,textarea,select');
				allFields.forEach((field) => {
					if (!field.dataset.model) {
						let n = field.name;
						n = n.replace(/\[/g, '.').replace(/\]/g, '').replace(/\.$/g, '');
						field.setAttribute('data-model', '$models.inline_reply.' + n);
					}
				});
			}
		});

        app.component('forum-thread-mod', componentForumMod);
		app.component('forum-posts-mod', componentForumPostsMod);




		app.component('forumInlineReply', forumInlineReply);
		app.component('forumPost', componentForumPost);
	},
};


// laravision.registerComponent('forumPost', {
// 	mount: (el, options) => bindForumPosts(el, options)
// });
