// import {ajaxLoading} from "./components/ajax-loading";
// import {ajaxNotification} from "./components/notification";

let meta = document.head.querySelector(
    'meta[name="csrf-token"]'
);

global.axios = require('axios');
global.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
global.axios.defaults.headers.common['X-CSRF-TOKEN'] = meta ? meta.content : '';

const defaultInterceptorsRequest = (config) => {
    if (!config.el) {
        // if (!globalScope.disableAjaxLoading) ajaxLoading(true);
    }
    return config;
};

const defaultInterceptorsResponse = [
    function(response) {

        if (!response.config.el) {
            // if (!globalScope.disableAjaxLoading) ajaxLoading();
        }

        if (response.data) {
            // ajaxNotification(response.data);
        }

        return response;
    },
    error => {
        if (error && error.hasOwnProperty('response')) {

            if (error.config && !error.config.el) {
                // if (!globalScope.disableAjaxLoading) ajaxLoading()
            }

            const { status } = error.response;

            if (error.response.data.hasOwnProperty('exception')) {
                // ajaxNotification(error.response.data);
                return;
            }

            // Handle Session Timeouts
            if (status === 401) {
                window.location.href = '/';
            }

            // Handle Forbidden
            if (status === 403) {

                if (error.response && error.response.data.session === false) {
                    console.log('Error 403');
                }
            }

            if (status === 404) {
                console.log('Error 404');
            }
        }


        return Promise.reject(error);
    }
];

global.axios.interceptors.request.use(defaultInterceptorsRequest);

global.axios.interceptors.response.use(
    defaultInterceptorsResponse[0],
    defaultInterceptorsResponse[1]
);

const dayjs = require('dayjs');
require('dayjs/locale/en');
require('dayjs/locale/de');

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
dayjs.locale('de');

global.dayjs =  dayjs;



