import tippy, {followCursor, animateFill} from 'tippy.js';

// @ts-ignore
export function bindDataTooltip( container = null) {
    // @ts-ignore
    container = container ? container : document;


    const tooltipDefaultOptions = {// @ts-ignore
        trigger: 'mouseenter focus',
        showOnCreate: false,
        arrow: true,
        inertia: true,
        interactive: false,
        animateFill: true,
        animation: 'shift-toward',
        theme: 'tooltip-dark',
        appendTo: () => document.body,
        allowHTML: true,
        // delay: [100, 0],// @ts-ignore
        // duration: [300, 0],
        followCursor: true,
        plugins: [animateFill, followCursor],
        popperOptions: {
            strategy: 'fixed',
            modifiers: [
                {
                    name: 'flip',
                    options: {
                        fallbackPlacements: ['bottom', 'right'],
                    },
                },
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        tether: false,
                    },
                },
            ]
        }
    };




    // @ts-ignore
    container.querySelectorAll('[data-tooltip]').forEach((el) => {
        let t = el.dataset.tooltip;
        if (t && t.trim() && el.tagName !== 'BUTTON') {
            let options = Object.assign(tooltipDefaultOptions, {content: t})
            // @ts-ignore
            el._tippy = tippy(el, options);
        }
    });

    // @ts-ignore
    container.querySelectorAll('a[title]').forEach((el) => {
        let t = el.title;
        if (t && t.trim() && el.tagName !== 'BUTTON') {
            let options = Object.assign(tooltipDefaultOptions, {content: t})
            // @ts-ignore
            el._tippy = tippy(el, options);// @ts-ignore
        }
    });
}
