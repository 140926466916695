import {insertBefore, insertAfter, htmlToElement} from "@/framework/DomHelpers";


function postData(url = '', data = {}) {
    return global.axios.post(url, data, {timeout: 800} );
}


export const quickEdit = (options = {}) => {
    const quickEditMenu = document.querySelector('.quick-edit-menu');
    if (!quickEditMenu) {
        return false
    }

    let config = Object.assign({
        forcevisible: false
    }, options);

    const body = document.querySelector('body');
    let foreceVisible = config.hasOwnProperty('forcevisible') && config.forcevisible;
    body.classList.add('has-quick-edit');

    if (foreceVisible) {
        body.classList.add('quick-edit-menu-visible')
    }

    quickEditMenu.querySelectorAll('.menu a').forEach((el) => {
        if (el.hasAttribute('data-params')) {

            if (!el.hasAttribute('data-component')) {
                el.setAttribute('data-component', 'modal');
                if (el.hasAttribute('data-params')) {
                    let params = JSON.parse(el.dataset.params);
                    Object.assign(params, {
                        ajaxLoad: false,
                        target: '#quick-edit-entry',
                        currentTab: null
                    })

                    el.setAttribute('data-params', JSON.stringify(params))
                }
            }


            let p = el.dataset.params;
            try {
                p = JSON.parse(p)
                // console.log(p)

                if (p && p.hasOwnProperty('model') && p.hasOwnProperty('id')) {

                    el.addEventListener('click', (e) => {
                        global.axios.post(e.target.dataset.url, p, {timeout: 800} ).then(r => {
                            if (r.data.success) {
                                let form = document.querySelector('#quick-edit-entry-form');
                                if (form) {
                                    form.setAttribute('data-scope', {'$models': { quickedit: r.data.model } });

                                    let modalBody = form.closest('.modal-body');

                                    if (typeof r.data.form_tabs === "string") {
                                        if (!modalBody.parentNode.querySelector('.modal-tabs')) {

                                            let tabs = document.createElement('div');
                                            tabs.classList.add('modal-tabs');

                                            let tabsInner = htmlToElement(r.data.form_tabs);
                                            tabs.appendChild(tabsInner)

                                            insertBefore(tabs, modalBody);
                                        }
                                        else {
                                            modalBody.parentNode.querySelector('.modal-tabs').innerHTML = r.data.form_tabs;
                                        }
                                    }

                                    if (modalBody) {
                                        modalBody.innerHTML = r.data.form_tabs_content;
                                    }


                                }
                            }
                        });
                    })

                }
            } catch (e) {
                console.error(e)
            }
        }
    });

    return true;
}



export function handleQuickeditPrepare(ctx, options, props) {
    console.log('handleQuickeditPrepare', ctx, options, props)
    let form = props.form;
    let modalContent = props.modalContent;


    if (options.trigger) {
        options.trigger.addEventListener('click', (e) => {
            ctx.scope.open(e);
        });
    }



}

export function handleQuickeditLoad(ctx, options, props, evt) {
    console.log('handleQuickeditLoad', ctx, options, props)
    let form = props.form;
    let modal = props.modal;
    let modalContent = props.modalContent;
    let url = options.trigger ? options.trigger.dataset.url : '';


    form.setAttribute('data-autoform', 'data-autoform');


    if (props.reset) {
        props.reset.addEventListener('click', () => {
            form.reset()
        });
    }

    if (props.cancel) {
        props.cancel.addEventListener('click', () => {
            ctx.scope.close()
        });
    }


    global.axios.post(url, {
        model: options.model,
        id: options.id
    }, {timeout: 800} ).then(r => {
        if (r.data.success) {
            // let form = document.querySelector('#quick-edit-entry-form');
            if (form) {


                // form.setAttribute('data-scope', {'$models': { quickedit: r.data.model } });

                let modalBody = form.closest('.modal-body');

                if (typeof r.data.form_tabs === "string") {
                    if (!modalBody.parentNode.querySelector('.modal-tabs')) {

                        let tabs = document.createElement('div');
                        tabs.classList.add('modal-tabs');

                        let tabsInner = htmlToElement(r.data.form_tabs);
                        tabs.appendChild(tabsInner)

                        insertBefore(tabs, modalBody);

                        let firstTab = tabs.querySelector('.nav-item');
                        if (firstTab && firstTab.getAttribute('rel')) {
                            if (!ctx.scope.hasOwnProperty('currentTab') || ctx.scope.currentTab === null ) {
                                ctx.scope.currentTab = firstTab.getAttribute('rel')
                            }
                        }
                    }
                    else {
                        let modalTabs = modalBody.parentNode.querySelector('.modal-tabs');
                        modalTabs.innerHTML = r.data.form_tabs;

                        let firstTab = modalTabs.querySelector('.nav-item');
                        if (firstTab && firstTab.getAttribute('rel')) {
                            ctx.scope.currentTab = firstTab.getAttribute('rel')
                        }
                    }
                }

                if (modalBody) {
                    form.innerHTML =  r.data.form_tabs_content;
                }

                // Rewalk the modal contents
                ctx.scope.walk(modal, ctx);
            }
        }
    });


}
