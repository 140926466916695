import AOS from 'aos';
global.AOS = AOS;

import {createApp, reactive} from "@/framework";
import {isObject, when} from "@/framework/utils";

import {syntax} from '@/framework/directives/syntax';
import {componentSyntax} from '@/framework/components/syntax';
import {componentModal} from '@/framework/components/modal';

import {componentToggleDarkmode} from '@/custom-components/darkmode-toggle';
import {componentLightbox, componentLightboxGallery} from '@/custom-components/lightbox';
import {componentBBCodeEditor} from '@/custom-components/bbcode-editor';
import {componentUpload} from "@/custom-components/upload";
import {componentAvatarUpload} from "@/custom-components/avatarupload";
import {componentNotification} from "@/custom-components/notification";


import {tooltip} from "@/custom-directives/tooltip";
import {hasAjaxNotification, ajaxNotification} from "@/utils/ajax";
import {bindDataTooltip} from "@/utils/tooltips";
import "@/vendors/vendors.js";

import {prepareBlog, prepareCaptchaModel, prepareCaptcha, preparePageDefaults} from "@/website";

import {tr} from '@modules/Statistic/Assets/js/frontend/tracker';
import {quickEdit, handleQuickeditLoad, handleQuickeditPrepare} from "./quick-edit";
import {ignoreModules} from "./IgnoreCmsModules";
window.handleQuickeditPrepare = handleQuickeditPrepare;
window.handleQuickeditLoad = handleQuickeditLoad;

document.addEventListener('readystatechange', event => {
	if (event.target.readyState === 'interactive') {
	}
	else if (event.target.readyState === 'complete') {

		const data = (window.data = reactive({}));

		when((resolve, reject) => {
			const app = createApp(data);
			global.app = window.app = app;
			resolve(app);
		})
			.then((app) => {

				// Core directives & components
				app.directive('tooltip', tooltip);
				app.directive('syntax', syntax);
				app.component('syntax', componentSyntax);
				app.component('modal', componentModal);


				// Custom directives & components
				app.component('lightbox', componentLightbox);
				app.component('lightbox-gallery', componentLightboxGallery);
				app.component('darkmode-toggle', componentToggleDarkmode);
				app.component('bbcode-editor', componentBBCodeEditor);
				app.component('upload', componentUpload);
				app.component('avatarupload', componentAvatarUpload);
				app.component('notification', componentNotification);







				// Add custom global methods
				app.hasAjaxNotification = hasAjaxNotification;
				app.ajaxNotification = ajaxNotification;
				app.removeFormErrors = (container) => {
					container.querySelectorAll('div.error').forEach((err) => {
						err.remove();
					});
				};


				// ------------ Mount Event Hooks -----------------

				// Add before mount hooks
				app.beforeMount((a, el, ctx) => {
					el.querySelectorAll('[title]').forEach((item) => {
						if (!item.dataset.hasOwnProperty('tooltip')) {
							item.setAttribute('data-tooltip', item.title)
						}
					});
					prepareCaptchaModel(el);
				});

				// Add after mount hooks
				app.afterMount((a, el, ctx) => {
					bindDataTooltip(el);
					prepareCaptcha(el);
					preparePageDefaults(a, el, ctx);
					prepareBlog();
				});

				return app;
			})
			.then((app) => {

			// Load modul components
			if (window.hasOwnProperty('Laravel') && Array.isArray(window.Laravel.modules)) {
				const {ignoreModules} = require("./IgnoreCmsModules");
				const modules = window.Laravel.modules.filter(item => !ignoreModules.includes(item));

				if (Array.isArray(modules)) {
					modules.forEach((name) => {

						try {
							let filesbasepage1 = require('@modules/' + name + '/Assets/js/Frontend' + name + 'Components.js').default;
							if (_.isObject(filesbasepage1)) {
								console.log('Load modul Frontend' + name + 'Components.js');
								app.use(filesbasepage1);
							}
						}
						catch (e) {
							try {
								let filesbasepage1 = require(`@modules/${name}/Assets/js/frontend-components.js`).default;
								if (isObject(filesbasepage1)) {
									console.log('Load modul frontend-components.js: ' + name);
									app.use(filesbasepage1);
								}
							}
							catch (e) {
								// console.warn('Modul "'+name+'" has no components.js');
							}
						}
					});
				}
			}

			return app;

		}).then((app) => {
			app.mount();

			return app;
		})

			.then(async (app) => {




                AOS.init({
                    useClassNames: true,
                    initClassName: true,
                    animatedClassName: 'animated',
                    duration: 500,
                });

                setTimeout(() => {
                    document.querySelectorAll('[autocomplete]').forEach((el) => {
                        el.setAttribute('autocomplete', 'off');
                        if (el.tagName !== 'FORM' && el.type !== 'hidden' ) {
                            el.value = '';
                        }
                    });
                }, 500);


                if (!quickEdit({})) {
                    document.body.classList.add('loaded');
                    let base = document.querySelector('base');
                    if (base && base.href) {
                        await tr(base.href.replace(/\/$/g, '') + '/hit/r');
                    }
                }



		});



	}
});
