import {makeModal} from "@/framework/components/modal";

const bindUserMessangerComponente = (el, ctx, options) => {
    if (el && !el.mounted) {
        return;
    }

    el.mounted = true;
    let form;
    if (el.tagName !== 'FORM') {
        form = el.querySelector('form');
    }
    else {
        form = el;
    }

    let headerCheckbox = el.querySelector('.bulk-checkbox input');
    let bodyCheckboxes = el.querySelectorAll('tbody .bulk-checkbox input[type=checkbox]');
    let bulkActionBtn = el.querySelector('.bulk-action-btn');
    let bulkActions = el.querySelector('.bulk-actions');
    let actionSelector = el.querySelector('.bulk-actions select[name=action]');

    if (!form || !bulkActionBtn || !actionSelector || !headerCheckbox || !bulkActions) {
        console.warn('Invalid component "account_messanger"!');
        return;
    }

    let checkAll = false,
        selectedAction = null,
        moveMessagesTo = null,
        selectedMessages = [];


    let props = {
        checkAll: false,
        selectedAction: null,
        moveMessagesTo: null,
        selectedMessages: []
    };

    reactive(props);

    observe('selectedMessages', (newVal, oldValue) => {
        if (!newVal.length) {
            bulkActions.classList.add('hide');
        }
        else {
            bulkActions.classList.remove('hide');
        }
    });



    bulkActionBtn.addEventListener('click', (e) => {
        if (e.target.classList.contains('disabled')) {
            return;
        }

        if (props.selectedAction === 'move') {
            displayMoveMessageTo(el, form, options, props);
        }
        else if (props.selectedAction === 'delete') {
            displayDeleteMessageConfirm(el, form, options, props);
        }
        else {
            form.submit();
        }
    });

    actionSelector.addEventListener('change', (e) => {
        props.selectedAction = e.target.value;
        if (!props.selectedAction) {
            bulkActionBtn.classList.add('disabled');
        }
        else {
            bulkActionBtn.classList.remove('disabled');
        }
    });

    bodyCheckboxes.forEach((cbk) => {
        cbk.addEventListener('change', (e) => {
            if (e.target.checked) {
                props.selectedMessages.push(e.target.value);
            }
            else {
                props.selectedMessages = props.selectedMessages.filter((id) => e.target.value !== id);
            }

            notify('selectedMessages', props.selectedMessages);
        });
    });

    headerCheckbox.addEventListener('change', (e) => {
        bodyCheckboxes.forEach((cbk) => {
            cbk.checked = e.target.checked;

            if (e.target.checked) {
                props.selectedMessages.push(e.target.value);
            }
            else {
                props.selectedMessages = props.selectedMessages.filter((id) => e.target.value !== id);
            }

            notify('selectedMessages', props.selectedMessages);
        });

        props.checkAll = e.target.checked;

    });

    notify('selectedMessages', props.selectedMessages);
};

function displayMoveMessageTo(el, form, options, props) {
    if (options.moveModal) {
        let moveModal = document.querySelector(options.moveModal);
        if (moveModal) {
            if (el.__moveModal) {
                const event = new Event('mouse');
                el.__moveModal.show(event);
                return;
            }

            const modal = bindModal(el, {
                target: options.moveModal,
                manually: true,
                ajaxLoad: false,
                closeOnClickOutside: false,
                draggable: true,
                events: {
                    onSubmit: (postData, closeModalCall) => {
                        if (closeModalCall) closeModalCall();

                        if (postData.new_folder_id) {
                            el.querySelector('[name=new_folder_id]').value = postData.new_folder_id;
                            form.submit();
                        }
                    }
                }
            });

            el.__moveModal = modal;
            const event = new Event('mouse');
            modal.show(event);


        }
    }
}

function displayDeleteMessageConfirm(el, form, options) {

}

export default {
    install(app, options) {
        app.beforeMount((app, el, ctx) => {

        });

        app.component('account_messanger', bindUserMessangerComponente);
    },
};
