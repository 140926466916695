import tippy from 'tippy.js';
import {Directive} from '../framework/directives';
import {evaluate} from '../framework/eval';
export const tooltip: Directive = ({ el, get, exp, effect, ctx, arg, modifiers }) => {
    let tip: string | null = null;

    if (exp) {
        exp = exp.trim();
        if (exp.match(/^['"]/g) && exp.match(/['"]$/g)) {
            tip = evaluate(ctx.scope, exp);
        }
        else {
            tip = exp
        }

        if (typeof tip === "string") {
            tippy(el, {
                content: tip,
                showOnCreate: false,
                theme: 'dark',
                placement:'top',
                arrow: true,
            });
        }
    }
}
