
import {nextTick} from "@/framework/scheduler";
import {getNextNode, insertAfter} from "@/framework/DomHelpers";
import {ajaxNotification, hasAjaxNotification} from "@/utils/ajax";
import {convertJsonToFormData} from "@/framework/utils";
import {svgIcon} from "@/framework/svg-icons";


const bindComment = (el, ctx, options) => {

	if (el && !el.mounted) {
		el.mounted = true;

		const form = document.querySelector('form[data-control="comments-form"]');

		if (!form) return;

		const editBtn = el.querySelector('.edit-btn');
		const replyBtn = el.querySelector('.reply-btn');
		const voteupBtn = el.querySelector('.voteup');
		const votedownBtn = el.querySelector('.votedown');

		let formName = form.getAttribute('name');
		let parentid = form.querySelector('[name=parentid]');
		let subject = form.querySelector('[name=subject]');

		form.addEventListener('reset', (e) => {
			e.preventDefault();
			subject.value = null;
			parentid.value = null;
		});


		if (replyBtn && parentid)
		{
			replyBtn.addEventListener('click', (e) => {
				e.preventDefault();

				parentid.value = options.id;
				subject.value = 'Re: '+ options.subject;

				form.scrollIntoView({block: 'start'});
			});
		}


		let voteupspan = voteupBtn ? voteupBtn.querySelector('span') : null;
		let votedownspan = votedownBtn ? votedownBtn.querySelector('span') : null;


		if (voteupBtn && options.voteurl) {
			if (voteupspan) {
				voteupBtn.addEventListener('click', (e) => {
					e.preventDefault();

					axios.post(options.voteurl, {direction: '+'})
						.then((r) => {
							if (r.data.success) {
								if (r.data.hasOwnProperty('voteup') && r.data.voteup >= 0) {
									voteupspan.innerText = r.data.voteup;
								}
								if (r.data.hasOwnProperty('votedown') && r.data.votedown >= 0) {
									votedownspan.innerText = r.data.votedown;
								}
							}
						});

				});
			}

		}



		if (votedownBtn && options.voteurl) {

			if (votedownspan) {
				votedownBtn.addEventListener('click', (e) => {
					e.preventDefault();
					axios.post(options.voteurl, {direction: '-'})
						.then((r) => {
							if (r.data.success) {
								if (r.data.hasOwnProperty('votedown') && r.data.votedown >= 0) {
									votedownspan.innerText = r.data.votedown;
								}
								if (r.data.hasOwnProperty('voteup') && r.data.voteup >= 0) {
									voteupspan.innerText = r.data.voteup;
								}
							}
						});
				});
			}

		}


		const oldData = options.hasOwnProperty('oldReplyData') ? options.oldReplyData : {};
		let loadOldReplayForm = false;
		if (options.hasOwnProperty('oldReplyData') && typeof options.oldReplyData === "object" ) {
			loadOldReplayForm = true;
		}

		if (editBtn)
		{
			// editBtn.addEventListener('click', (e) => {
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// });
		}

		if (replyBtn && options.hasOwnProperty('inlineReplyUrl') && options.inlineReplyUrl ) {
			let replayContainer = getNextNode(el);
			let replayFormContainer;


			if (!replayContainer || (replayContainer && (replayContainer.nodeType === 3 || (replayContainer.classList && !replayContainer.classList.contains('replay-container')) ) ) ) {
				replayContainer = document.createElement('div');
				replayContainer.classList.add('replay-container');

				let div = document.createElement('div');
				div.innerHtml = '<button type="button" class="btn btn-default cancel-reply">Abbrechen</button>';
				replayContainer.appendChild(div);


				replayFormContainer = document.createElement('div');
				replayFormContainer.classList.add('replay-form');
				replayContainer.appendChild(replayFormContainer);

				insertAfter(replayContainer, el);
			}
			else {
				replayFormContainer = replayContainer.querySelector('.replay-form');
			}

			let cancel = replayContainer.querySelector('.cancel-reply');
			if (!cancel) {
				replayContainer.querySelector('div:first-of-type').innerHTML = '<button type="button" class="btn btn-default cancel-reply">Abbrechen</button>';
				cancel = replayContainer.querySelector('.cancel-reply');
			}


			if (cancel) {
				cancel.addEventListener('click', (e) => {
					e.preventDefault();
					e.stopPropagation();
					replayFormContainer.innerHTML = '';
					replayContainer.classList.remove('open');
				});
			}


			replyBtn.addEventListener('click', (e) => {
				e.preventDefault();
				e.stopPropagation();

				// Close all other opened Forms
				el.parentNode.querySelectorAll('.replay-container.open').forEach((container) => {
					container.classList.remove('open');
					let replayCon = container.querySelector('.replay-form');
					if (replayCon) {
						replayCon.innerHTML = '';
					}
				});


				axios.get(options.inlineReplyUrl)
					.then((r) => {
					if (r.data.success) {
						replayFormContainer.innerHTML = r.data.form;
						ctx.scope.walk(replayFormContainer, ctx);

						if (loadOldReplayForm) {
							let input;
							if (oldData.hasOwnProperty('created_by_name')) {
								if ((input = replayContainer.querySelector('[name=created_by_name]'))) {
									input.value = oldData.created_by_name;
								}
							}

							if (oldData.hasOwnProperty('created_by_email')) {
								if ((input = replayContainer.querySelector('[name=created_by_email]'))) {
									input.value = oldData.created_by_email;
								}
							}

							if (oldData.hasOwnProperty('title')) {
								if ((input = replayContainer.querySelector('[name=title]'))) {
									input.value = oldData.title;
								}
							}

							if (oldData.hasOwnProperty('content')) {
								if ((input = replayContainer.querySelector('[name=content]'))) {
									input.value = oldData.content;
								}
							}
						}

						replayContainer.classList.add('open');
						replayContainer.scrollIntoView({ behavior: 'smooth', block: 'start'});

					}
					else {
						ajaxNotification(r.data);
					}
				});

				console.log('replyBtn');
			});


			if (loadOldReplayForm) {
				const replyEvent = new Event('click');
				replyBtn.dispatchEvent(replyEvent);
			}
		}
	}
};



const bindCommentsLoader = (el, ctx, options) => {
    if (el && !el.mounted) {
        el.mounted = true;

        let config = Object.assign({ perpage: 20, page: 1, treeListSelector: '#comments' }, options);

        let list = document.querySelector(config.treeListSelector);

        let btn = el.querySelector('[data-control="load-comments"]');

        if (btn && btn.tagName === 'BUTTON' && list) {

            btn.addEventListener('click', (evt) => {
                if (config.current_count < config.total || config.page < Math.ceil(config.total/config.perpage) ) {
                    let nextPage = config.page + 1;
                    axios.post(options.url, {
                            posttype: config.posttype,
                            postid: config.postid,
                            limit: config.perpage,
                            page: nextPage,
                            current_count: config.current_count
                    })
                        .then((r) => {
                            if (r.data.success) {
                                list.innerHTML += r.data['comment-tree'];
                                config.page = r.data.page;
                                config.current_count = config.page * config.perpage > config.total ? config.total : config.page * config.perpage;


                                if (config.current_count >= config.total) {
                                    btn.classList.add('hide')
                                }

                            }
                        });
                }
            });
        }
	}
};


const componentLoadComments= (el, ctx, exp, get, options) => {
    bindCommentsLoader(el, ctx, options);
};
const componentComment = (el, ctx, exp, get, options) => {
	bindComment(el, ctx, options);
};

export default {
	install(app, options) {


		// app.beforeMount((app, el, ctx) => {
		// 	const submitForm = el.tagName === 'FORM' ? el : el.querySelector('[data-component="forumInlineReply"]');
		// 	if (submitForm && submitForm.dataset.component === 'forumInlineReply' && submitForm.name === 'inline_reply') {
		// 		const allFields = submitForm.querySelectorAll('input,textarea,select');
		// 		allFields.forEach((field) => {
		// 			if (!field.dataset.model) {
		// 				let n = field.name;
		// 				n = n.replace(/\[/g, '.').replace(/\]/g, '').replace(/\.$/g, '');
		// 				field.setAttribute('data-model', '$models.inline_reply.' + n);
		// 			}
		// 		});
		// 	}
		// });



		// app.component('forumInlineReply', forumInlineReply);
		app.component('comment', componentComment);
        app.component('comments_loader', componentLoadComments);
	},
};


// laravision.registerComponent('forumPost', {
// 	mount: (el, options) => bindForumPosts(el, options)
// });
