function postData(url = '', data = {}) {
	return global.axios.post(url, data, {timeout: 800} );

	const controller = new AbortController();
	const signal = controller.signal;

	setTimeout(() => controlller.abort(), 250);

	// Default options are marked with *
	const response = fetch(url, {
		signal: signal,
		method: 'POST', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-store', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json'
			// 'Content-Type': 'application/x-www-form-urlencoded',
		},
		redirect: 'error', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	return response.json(); // parses JSON response into native JavaScript objects
}

export function tr(u) {
	let to = document.querySelector('[name="csrf-token"]');
	to = (to ? to.getAttribute('content') : '');
	let data = {};

	if (to && navigator && navigator.userAgentData && typeof navigator.userAgentData.getHighEntropyValues === "function") {

		const result = navigator.userAgentData.getHighEntropyValues(["architecture", "platform", "platformVersion"])
			.then((e) => {
			if (e) {
				if (e.architecture) {
					data.arch = e.architecture;
				}
				if (e.platform) {
					data.p = e.platform;
				}
				if (e.platformVersion) {
					data.pV = e.platformVersion;
				}
			}
		});

		result.then(() => {
				data._token = to;
				data.loc = window.location.href;

				postData(u, data)
					.then((r) => {
					if (!r.data.success) {
						// console.warn('...');
					}
				});
		});
	}
	else {
		data._token = to;
		data.loc = window.location.href;
		data.ua = navigator.userAgent;
		postData(u, data).then((r) => {
			if (!r.data.success) {
				// console.warn('...');
			}
		});
	}
}



